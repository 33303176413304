import { Box, Grid } from "@mui/material";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectFacilityId } from "store/slices/authSlice/AuthSlice";
import { TableSideChargesFields } from "./form.constants";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import { useGetFacilityTableSideMealPricesQuery, usePostFacilityTableSideMealPricesMutation } from "store/apis/FacilityApis";

export default function MealPrices() {

  const facilityId = useSelector(selectFacilityId);

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = methods;

  const {
    data: facilityMealPrices,
    isLoading: pricesLoading,
    isSuccess: pricesSuccess,
    refetch: refetchMealPrices,
  } = useGetFacilityTableSideMealPricesQuery();

  const [
    addMealPrices,
    { isSubmitting: addSaving, isSuccess: addSuccess },
  ] = usePostFacilityTableSideMealPricesMutation();

  useEffect(() => {
    if (!pricesLoading && pricesSuccess) {
      reset(facilityMealPrices);

    }
  }, [facilityMealPrices, pricesLoading, pricesSuccess]);

  useEffect(() => {
    if (!addSaving && addSuccess) {
      refetchMealPrices();
    }
  }, [addSaving, addSuccess]);

  const onSubmit = (data) => {
    //needed when no prices saved yet for facility, otherwise reset() populates it on load
    if (!data?.facilityId) {
      data["facilityId"] = facilityId;
    }

    addMealPrices(data);  
  }

  return (
    <>
      <Grid container sx={{margin: "2rem 10% 3rem", width: "80%"}} alignItems="flex-start" justifyContent="space-around" flexWrap="nowrap">
        <GeneratedForm
          control={control}
          list={TableSideChargesFields}
          errors={errors}
        />
        <Box 
          display="flex" 
          alignSelf="center"  
          alignItems="center" 
          justifyContent="flex-end" 
          sx={{marginBottom: isValid ? "0" : "1.25rem"}}
        >
          <BaseSubmitButton
            isSubmitting={addSaving} 
            disabled={!isValid}
            onClick={handleSubmit(onSubmit)} 
          />
        </Box>
      </Grid>
    </>
  );
}