import { REQUIRED_ERROR } from "utils/errorMessages";
import { positiveDollarNumber } from "../../../utils/ValidationRegex";
import { FORM_TYPES } from "components/shared/generatedForm/GeneratedForm";

const mealOptions = [
  { id: 1, label: "Breakfast" },
  { id: 2, label: "Lunch" },
  { id: 3, label: "Dinner" },
];

export const TableSideChargesFields = [
  {
    props: {
      name: "breakfastPrice",
      id: "breakfastPrice",
      label: "Breakfast",
      type: "number",
      unit: "$",
      unitPosition: "start",
      defaultValue: 0
    },
    validationProps: {
      required: REQUIRED_ERROR("Breakfast Price"),
      max: {
        value: 99.99,
        message: "Price must be less than $100"
      },
      pattern: {
        value: positiveDollarNumber,
        message: "Please enter a valid dollar amount",
      },
    },
    size: 2,
  },
  {
    props: {
      name: "lunchPrice",
      id: "lunchPrice",
      label: "Lunch",
      type: "number",
      unit: "$",
      unitPosition: "start",
      defaultValue: 0
    },
    validationProps: {
      required: REQUIRED_ERROR("Lunch Price"),
      max: {
        value: 99.99,
        message: "Price must be less than $100"
      },
      pattern: {
        value: positiveDollarNumber,
        message: "Please enter a valid dollar amount",
      },
    },
    size: 2,
  },
  {
    props: {
      name: "dinnerPrice",
      id: "dinnerPrice",
      label: "Dinner",
      type: "number",
      unit: "$",
      unitPosition: "start",
      defaultValue: 0
    },
    validationProps: {
      required: REQUIRED_ERROR("Dinner Price"),
      max: {
        value: 99.99,
        message: "Price must be less than $100"
      },
      pattern: {
        value: positiveDollarNumber,
        message: "Please enter a valid dollar amount",
      },
    },
    size: 2,
  }
];

export const TableSideCustomChargesFields = [
  {
    props: {
      name: "chargeTypeId",
      id: "chargeTypeId",
      label: "New Charge",
    },
    validationProps: {
      required: REQUIRED_ERROR("Charge Type")
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "getTableSideChargeTypeSelect",
    size: 2,
  },
  {
    props: {
      name: "chargeTypeText",
      id: "chargeTypeText",
      label: "Charge Type",
      type: "text",
    },
    validationProps: {
      required: REQUIRED_ERROR("Charge Text")
    },
    size: 3
  },
  {
    props: {
      name: "mealId",
      id: "mealId",
      label: "Meal",
      defaultValue: {
        id: 0,
        label: "All",
      },
    },
    validationProps: {
      required: REQUIRED_ERROR("Meal"),
    },
    size: 3,
    type: FORM_TYPES.MultiSelect,
    options: mealOptions,
  },
  {
    props: {
      name: "price",
      id: "price",
      label: "Price",
      type: "number",
      unit: "$",
      unitPosition: "start",
      defaultValue: 0
    },
    validationProps: {
      required: REQUIRED_ERROR("Price"),
      min: {
        value: 0.01,
        message: "Price must be greater than 0.",
      },
      max: {
        value: 99.99,
        message: "Price must be less than $100" 
      },
      pattern: {
        value: positiveDollarNumber,
        message: "Please enter a valid dollar amount",
      },
    },
    size: 2,
  }
];

export const TableSideItemizedPricesFields = [
  {
    props: {
      name: "seasonId",
      id: "seasonId",
      label: "Season",
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "seasonSelect",
    size: 6,
  },
  {
    props: {
      name: "menuId",
      id: "menuId",
      label: "Menu",
    },
    validationProps: {
      required: REQUIRED_ERROR("Menu"),
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "getMenusBySeasonSelectBox",
    size: 6,
  }
]
