import { REQUIRED_ERROR } from "utils/errorMessages";
import { FORM_TYPES } from "../../shared/generatedForm/GeneratedForm";

const mealOptions = [
  { id: 0, label: "All" },
  { id: 1, label: "Breakfast" },
  { id: 2, label: "Lunch" },
  { id: 3, label: "Dinner" },
];

const SortOrder = [
  {
    id: 5,
    label: "Dining Area/Table/Room #",
  },
  {
    id: 3,
    label: "Dining Area/Resident",
  },
  {
    id: 4,
    label: "Dining Area/Room #",
  },
  {
    id: 1,
    label: "Resident",
  },
  {
    id: 2,
    label: "Wing/Room #",
  },
  {
    id: 6,
    label: "Wing/Resident",
  },
  {
    id: 7,
    label: "Room #",
  },
  {
    id: 8,
    label: "Day/Resident",
  },
];

export const printOptionsFields = [
  {
    props: {
      name: "sortOrder",
      label: "Sort Order",
      defaultValue: {
        id: 5,
        label: "Dining Area/Table/Room #",
      },
    },
    validationProps: {},
    size: 12,
    options: SortOrder,
    type: FORM_TYPES.Select,
  },
  {
    props: {
      name: "includePortionSize",
      label: "Include Portion Sizes on Menu Items",
    },
    validationProps: {},
    size: 12,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {
      name: "useMealCardHighlighting",
      label: "Enable MealCard Highlighting",
    },
    validationProps: {},
    size: 12,
    type: FORM_TYPES.Checkbox,
  },
];

export const TicketPrintingFields = [
  {
    props: {
      name: "mealId",
      id: "mealId",
      label: "Meal",
      defaultValue: {
        id: 0,
        label: "All",
      },
    },
    validationProps: {
      required: REQUIRED_ERROR("Meal"),
    },
    size: 6,
    type: FORM_TYPES.Select,
    options: mealOptions,
  },
  {
    props: {
      name: "diningAreaId",
      id: "diningAreaId",
      label: "Dining Area",
      defaultValue: {
        id: null,
        label: "All Dining Areas",
      },
    },
    size: 6,
    type: FORM_TYPES.SelectAPI,
    endpoint: "residentDiningareaSelectAll",
  },
  {
    props: {
      name: "startDate",
      label: "Start Date",
      placeholder: "Start Date",
    },
    validationProps: {},
    type: FORM_TYPES.DatePicker,
    size: 6,
  },
  {
    props: {
      name: "endDate",
      label: "End Date",
      placeholder: "End Date",
    },
    type: FORM_TYPES.DatePicker,
    validationProps: {},
    size: 6,
  },
  {
    props: {
      name: "showAll",
      id: "showAll",
      label: "Include printed tickets",
    },
    type: "checkbox",
    size: 6,
  },
];
