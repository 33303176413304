import { Box, Button, Dialog, Slide, Typography } from "@mui/material";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import React, { forwardRef } from "react";
import { printOptionsFields } from "./form.constants";
import { useForm } from "react-hook-form";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function PrintOptionsModal({open, handleClose}) {

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    getValues,
    formState: { errors },
  } = methods;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
    >
      <Typography
        variant="h5"
        textAlign="center"
        sx={{backgroundColor: "var(--tealColor)", color: "#FFF"}}
      >
        Print MealCards
      </Typography>
      <Box sx={{minWidth: "450px", height: "225px", padding: "1rem 2rem", justifyContent: "center", alignItems: "center"}}>
        <GeneratedForm
          list={printOptionsFields}
          control={control}
          slotProps={{
            listbox: {
              style: {
                maxHeight: "150px"
              }
            }
          }}
          errors={errors}
          marginY=".5rem"
        />
      </Box>
      <Box display="flex" flexWrap="nowrap" justifyContent="space-around" sx={{marginBottom: "1rem"}}>
        <Button variant="contained" onClick={() => handleClose('cancel')} color={"warning"}>
          Cancel
        </Button>
        <BaseSubmitButton
          onClick={() => handleClose(getValues('sortOrder'), getValues('includePortionSize'), getValues('useMealCardHighlighting'))}
          isSubmitting={false} 
          text="Print"
        />
      </Box>
    </Dialog>
  );
}